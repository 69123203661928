import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router'
// create an axios instance  创建axios的实例
const service = axios.create({
  baseURL: 'https://api.osai.cc/ososapi/index.php', // url = base url + request url  //请求的基础路径
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor  请求拦截器
service.interceptors.request.use()

// response interceptor  响应拦截器

service.interceptors.response.use(
  res => {
    // 如果响应的是image/jpeg数据类型
    if (res.headers['content-type'] === 'image/jpeg') {
      return res
    }
    // 第一个是成功回调(网络层面)
    const { ok, msg } = res.data
    if (ok) {
      // 成功
      return res.data
    } else {
      Message.error(msg)
      return Promise.reject(new Error('失败'))
    }
  },
  err => {
    console.dir(err)
    Message.error(err.message)
    // 第二个是失败回调(网络层面)
    return Promise.reject(err)
  }
)

export default service
